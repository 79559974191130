import service from './interceptors'

const RequestService = {
  getRequests: function (params?: object) {
    return service({
      url: 'request',
      method: 'get',
      params,
    })
  },

  updateRequest: function (id: string | number, data: object) {
    return service({
      url: `request/${id}`,
      method: 'put',
      data,
    })
  },

  decide: function (id: number, data: object) {
    return service({
      url: `request/${id}/status`,
      method: 'post',
      data,
    })
  },

  getTypes: function () {
    return service({
      url: 'utility/request-type',
      method: 'get',
    })
  },

  updateWithStatus: function (id: string | number, data: object) {
    return service({
      url: `request/${id}/_status`,
      method: 'post',
      data,
    })
  },

  getObjectTypes: function () {
    return service({
      url: 'utility/request-object-type',
      method: 'get',
    })
  },

  addObjectType: function (data: object) {
    return service({
      url: 'utility/request-object-type',
      method: 'post',
      data,
    })
  },

  updateObjectType: function (id: number, data: object) {
    return service({
      url: `utility/request-object-type/${id}`,
      method: 'put',
      data,
    })
  },

  deleteObjectType: function (id: number) {
    return service({
      url: `utility/request-object-type/${id}`,
      method: 'delete',
    })
  },
}

export default RequestService
