import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Alert, Box, InputAdornment, Stack, Typography } from '@mui/material'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { User } from 'src/@types/employee'
import Iconify from 'src/components/Iconify'
import { FormProvider, RHFTextField } from 'src/components/hook-form'
import { useLocales } from 'src/locales'
import AuthService from 'src/services/AuthService'
import EmployeeService from 'src/services/EmployeeService'
import * as Yup from 'yup'

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  setData: React.Dispatch<React.SetStateAction<LoginInfoValues | null>>
  loginInfo: LoginInfoValues | null
}

export type LoginInfoValues = {
  email: string
  password: string
  passwordConfirmation: string
  afterSubmit?: string
  userExists: boolean
}

export default function LoginInfoForm({ loginInfo, setStep, setData }: Props) {
  const { translate: t } = useLocales()

  const [showPassword, setShowPassword] = useState({
    password: false,
    passwordConfirmation: false,
  })

  const [userExists, setUserExists] = useState<boolean>(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${t('error.invalidEmail')}`)
      .required(`${t('error.emailRequired')}`),
    password: Yup.string().when('userExists', {
      is: false,
      then: Yup.string()
        .required(`${t('error.passwordRequired')}`)
        .min(8, `${t('error.passwordMin8')}`)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          `${t('error.passwordValid')}`
        ),
    }),
    passwordConfirmation: Yup.string().when('userExists', {
      is: false,
      then: Yup.string().oneOf([Yup.ref('password')], `${t('error.passwordConfirmation')}`),
    }),
  })

  const methods = useForm<LoginInfoValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: loginInfo?.email || '',
      password: loginInfo?.password || '',
      passwordConfirmation: '',
      userExists,
    },
  })

  const {
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
  } = methods

  const onSubmit = async (data: LoginInfoValues) => {
    //   try {
    //     const emailResponse = await AuthService.checkModel({
    //       type: 7, // organization
    //       filters: [{ field: 'email', value: data.email }],
    //     })
    //     if (emailResponse.data.data.exists) {
    //       throw Error(`${t('error.existingEmail')}`)
    //     }
    setStep(4)
    console.log(data)
    setData(data)
    //   } catch (error) {
    //     setError('afterSubmit', { ...error, message: error.message })
    //   }
  }

  const handleUserCheckFetch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    AuthService.checkModel({
      type: 8,
      filters: [{ field: 'email', value: email }],
    })
      .then((res) => {
        setUserExists(res.data.data.exists)
        setValue('userExists', res.data.data.exists)
        console.log(getValues('userExists'))
      })
      .catch((err) => {
        setUserExists(false)
        setValue('userExists', false)
      })
  }, 200)

  useEffect(() => {
    loginInfo &&
      handleUserCheckFetch({
        target: { value: loginInfo.email },
      } as React.ChangeEvent<HTMLInputElement>)
  }, [loginInfo, handleUserCheckFetch])

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {`${t('loginInfo')}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${t('loginInfoSubheader')}`}
          </Typography>
        </Box>

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField
          name="email"
          label={`${t('email')}`}
          inputProps={{ onChange: handleUserCheckFetch }}
        />

        {!userExists && (
          <RHFTextField
            name="password"
            label={`${t('password')}`}
            type={showPassword.password ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowPassword({ ...showPassword, password: !showPassword.password })
                    }
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}

        {!userExists && (
          <RHFTextField
            name="passwordConfirmation"
            label={`${t('passwordConfirmation')}`}
            type={showPassword.passwordConfirmation ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        passwordConfirmation: !showPassword.passwordConfirmation,
                      })
                    }
                    edge="end"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}

        <Box sx={{ textAlign: 'right' }}>
          <Button color="inherit" variant="text" sx={{ mr: 1, px: 2 }} onClick={() => setStep(2)}>
            {`${t('back')}`}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              bgcolor: '#1A0289',
              color: '#fff',
              '&:hover': {
                bgcolor: '#1A0289',
                color: '#fff',
              },
            }}
          >
            {`${t('continue')}`}
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  )
}
