import { CalendarView } from 'src/@types/shift'
import { LabelColor } from 'src/components/Label'
import { ColorSchema } from 'src/theme/palette'

const CONSTANTS = {
  questionType: {
    form: 0,
  },
  answerType: {
    text: 0,
    option: 1,
    checkbox: 2,
  },
  targets: {
    employee: 0,
    department: 1,
  },
  maxSize: 1000000,
}

const REQUEST: RequestTypes = {
  types: [
    { id: 0, label: 'unpaid' },
    { id: 1, label: 'paid' },
    { id: 2, label: 'timeRequestStart' },
    { id: 3, label: 'timeRequestEnd' },
    { id: 4, label: 'timeRequestFull' },
  ],
  additionalTypes: {
    9: 'Бусад',
    10: 'Өвчтэй',
    11: 'Сургалт',
  },
  status: {
    waiting: { id: 0, label: 'Хүлээгдэж буй', color: 'warning' },
    accepted: { id: 1, label: 'Зөвшөөрсөн', color: 'success' },
    rejected: { id: 2, label: 'Цуцалсан', color: 'error' },
  },
  statusArray: [
    { value: 0, label: 'Хүлээгдэж буй', color: 'warning' },
    { value: 1, label: 'Зөвшөөрсөн', color: 'success' },
    { value: 2, label: 'Цуцалсан', color: 'error' },
  ],
  actions: [
    { value: 0, label: 'other', color: 'warning' },
    { value: 1, label: 'accepted', color: 'success' },
    { value: 2, label: 'rejected', color: 'error' },
  ],
  objectTypes: [
    { value: 0, label: 'objectTypes.leave' },
    { value: 1, label: 'objectTypes.overtime' },
    { value: 2, label: 'objectTypes.vacation' },
    { value: 3, label: 'objectTypes.overtimeLeave' },
    { value: 4, label: 'objectTypes.businessTrip' },
    { value: 5, label: 'objectTypes.offsite' },
    { value: 6, label: 'objectTypes.timeRequest' },
    { value: 7, label: 'objectTypes.longTerm' },
    { value: -1, label: 'objectTypes.all' },
  ],
  labelNames: {
    status: 'request.status',
    start_date: 'request.start_date',
    end_date: 'request.end_date',
    object_type: 'type',
    type: 'request.requestType',
    request_object_type_id: 'request.additional',
  },
}

export const REQUEST_TYPES: Record<number, { label: string }> = {
  0: { label: 'Цалингүй' },
  1: { label: 'Цалинтай' },
  2: { label: 'Цаг нөхөх - эхлэх' },
  3: { label: 'Цаг нөхөх - дуусах' },
  4: { label: 'Цаг нөхөх - бүтэн' },
}

export const REQUEST_OBJECT_TYPES: Record<number, { label: string }> = {
  0: { label: 'objectTypes.leave' },
  1: { label: 'objectTypes.overtime' },
  2: { label: 'objectTypes.vacation' },
  3: { label: 'objectTypes.overtimeLeave' },
  4: { label: 'objectTypes.businessTrip' },
  5: { label: 'objectTypes.offsite' },
  6: { label: 'objectTypes.timeRequest' },
  7: { label: 'objectTypes.longTerm' },
  8: { label: 'objectTypes.document' },
}

export const REQUEST_OBJECT_TYPES_ARRAY = [
  { id: 0, label: 'objectTypes.leave' },
  { id: 1, label: 'objectTypes.overtime' },
  { id: 2, label: 'objectTypes.vacation' },
  { id: 3, label: 'objectTypes.overtimeLeave' },
  { id: 4, label: 'objectTypes.businessTrip' },
  { id: 5, label: 'objectTypes.offsite' },
  { id: 6, label: 'objectTypes.timeRequest' },
  { id: 7, label: 'objectTypes.longTerm' },
  { id: 8, label: 'objectTypes.document' },
]

export const REQUEST_OBJECT_TYPE_LEAVE = 0
export const REQUEST_OBJECT_TYPE_OVERTIME = 1
export const REQUEST_OBJECT_TYPE_VACATION = 2
export const REQUEST_OBJECT_TYPE_OVERTIME_LEAVE = 3
export const REQUEST_OBJECT_TYPE_BUSINESS_TRIP = 4
export const REQUEST_OBJECT_TYPE_OUTSIDE = 5
export const REQUEST_OBJECT_TYPE_TIME_RECOUP = 6
export const REQUEST_OBJECT_TYPE_LONG_LEAVE = 7
export const REQUEST_OBJECT_TYPE_DOCUMENT = 8

const TIMESHIFT = {
  type: [
    { value: 0, label: 'Тогтмол' },
    { value: 1, label: 'Ээлжийн' },
    { value: 2, label: 'Чөлөөт' },
    { value: 3, label: 'Хугацаат' },
  ],
}

const LOG: LogTypes = {
  action: [
    { value: 0, label: 'Үүсгэх', color: 'success' },
    { value: 1, label: 'Засах', color: 'info' },
    { value: 2, label: 'Устгах', color: 'error' },
  ],
  type: [
    { value: 2, label: 'features.attendance' },
    { value: 3, label: 'features.requests' },
  ],
  objectType: {
    timeshift: 1,
    attendance: 2,
    request: 3,
    employee: 5,
    department: 6,
  },
}

const TARGET = {
  types: [
    { id: 0, label: 'all' },
    { id: 1, label: 'employee' },
    { id: 2, label: 'department' },
  ],
  typesObject: {
    company: 0,
    employee: 1,
    department: 2,
  },
}

const EMPLOYEE: EmployeeType = {
  status: {
    active: { id: 0, label: 'Идэвхитэй' },
    inactive: { id: 1, label: 'Идэвхигүй' },
    leave: { id: 2, label: 'Ажлаас гарсан' },
  },
  statusArr: [
    { id: 0, label: 'active', color: 'success' },
    { id: 1, label: 'inactive', color: 'default' },
    { id: 2, label: 'resigned', color: 'warning' },
  ],
}

export { REQUEST, TARGET, LOG, TIMESHIFT, EMPLOYEE }
export default CONSTANTS

type EmployeeType = {
  status: {
    active: object
    inactive: object
    leave: object
  }
  statusArr: { id: number; label: string; color: LabelColor }[]
}

type RequestTypes = {
  types: { id: number; label: string }[]
  // additionalTypes: {id: number, label: string}[],
  additionalTypes: Record<number, string>
  status: object
  statusArray: { value: number; label: string; color: LabelColor }[]
  actions: { value: number; label: string; color: LabelColor }[]
  objectTypes: { value: number; label: string }[]
  labelNames: Record<string, string>
}

type LogTypes = {
  action: { value: number; label: string; color: LabelColor }[]
  type: { value: number; label: string }[]
  objectType: {
    timeshift: number
    attendance: number
    request: number
    employee: number
    department: number
  }
}

export const DURATION_TYPE_MONTHLY = 1
export const DURATION_TYPE_ANNUALLY = 2
export const DURATION_TYPE_SEASON = 4

export const DURATION_TYPES: Record<number, { name: string; duration: number }> = {
  [DURATION_TYPE_MONTHLY]: {
    name: '1 сар',
    duration: 1,
  },
  [DURATION_TYPE_ANNUALLY]: {
    name: '12 сар',
    duration: 12,
  },
  [DURATION_TYPE_SEASON]: {
    name: '3 сар',
    duration: 3,
  },
}

export const PAYMENT_METHOD_QPAY = 0
export const PAYMENT_METHOD_TRANSFER = 10

export const STATUS_PENDING = 0
export const STATUS_SUCCESS = 1
export const STATUS_FAILED = 2

export const FORM_TYPE_NORMAL = 0
export const FORM_TYPE_KPI = 1

export const TAG_TYPE_NORMAL = 0
export const TAG_TYPE_KPI = 1

export const TIMESHIFT_REPEAT_OPTIONS = [
  { id: '0', label: 'schedule.noRepeat' },
  { id: '1', label: 'schedule.repeatWeekly' },
]

export const TIMESHIFT_TYPE_NO_REPEAT = '0'
export const TIMESHIFT_TYPE_REPEAT_WEEK = '1'

export const COLOR_OPTIONS = [
  '#1A936F',
  '#1890FF',
  '#EE964B',
  '#f2c14e',
  '#FF4842',
  '#0D3B66',
  '#E0607E',
]

export const TIMESHIFT_VIEW_OPTIONS: { id: CalendarView; label: string }[] = [
  { id: 'week', label: 'Долоо хоног' },
  { id: 'month', label: 'Сар' },
]

export const TIMESHIFT_TYPE_NORMAL = 0
export const TIMESHIFT_TYPE_DAILY = 1

export const TIMESHIFT_FILTER_TYPE_EMP = 1
export const TIMESHIFT_FILTER_TYPE_DEP = 2

export const TIMESHIFT_FILTER_TYPES = [
  { id: 1, label: 'employee' },
  { id: 2, label: 'department' },
]

export const DAY_LABELS: Record<number, string> = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday',
}

export const TIMESHIFT_ITEM_TYPE_NORMAL = 1
export const TIMESHIFT_ITEM_TYPE_TEMPLATE = 2

export const TTIMESHIFT_TYPES: Record<number, string> = {
  0: 'timeshift.daily',
  1: 'timeshift.shift',
  2: 'timeshift.free',
  3: 'timeshift.duration',
}

export const TASK_DEFAULT_COLOR = '#1A0289'

export const STATUSES: Record<number, { label: string; color: ColorSchema }> = {
  [STATUS_PENDING]: { label: 'pending', color: 'warning' },
  [STATUS_SUCCESS]: { label: 'accepted', color: 'success' },
  [STATUS_FAILED]: { label: 'cancelled', color: 'error' },
}

export const INVOICE_METHODS = [{ id: 0, label: 'qpay' }]

export const SUBSCRIPTION_DURATION_TYPE_WEEKLY = 0
export const SUBSCRIPTION_DURATION_TYPE_MONTHLY = 1
export const SUBSCRIPTION_DURATION_TYPE_ANNUALY = 2
export const SUBSCRIPTION_DURATION_TYPE_SPECIFIC = 3
export const SUBSCRIPTION_DURATION_TYPE_SEASON = 4

export const SUBSCRIPTION_DURATION_TYPES: Record<number, { label: string }> = {
  [SUBSCRIPTION_DURATION_TYPE_WEEKLY]: { label: 'durations.weekly' },
  [SUBSCRIPTION_DURATION_TYPE_MONTHLY]: { label: 'durations.month' },
  [SUBSCRIPTION_DURATION_TYPE_ANNUALY]: { label: 'durations.yearly' },
  [SUBSCRIPTION_DURATION_TYPE_SPECIFIC]: { label: '-' },
  [SUBSCRIPTION_DURATION_TYPE_SEASON]: { label: 'durations.quarter' },
}

export const ROLE_SUPERADMIN = 0
export const ROLE_ADMIN = 1